import { CustomElement } from 'aurelia';

import 'op-flex/op-flex.css';

import Home from './home';
import Portfolio from './portfolio';
import About from './about';
import Blog from './blog';

import Template from './app.html';

export default CustomElement.define({
  template: Template,
  dependencies: [ Home, Portfolio, About, Blog ]
}, class {
  constructor() {
    this.page = 'home';

    if (window.location.hash) this.page = window.location.hash.substring(1);

    this.startScreen = true;
    document.body.style.overflow = 'hidden';
    window.addEventListener('wheel', this.triggerStart);
    window.addEventListener('touchstart', this.triggerStart);
    window.addEventListener('click', this.triggerStart);

    window.scrollTo(0, 0);
  }

  go = (to) => {
    this.page = to;
    window.history.pushState(null, null, '#' + to);
  }

  triggerStart = () => {
    this.startScreen = false;
    window.removeEventListener('wheel', this.triggerStart);
    window.removeEventListener('touchstart', this.triggerStart);
    window.removeEventListener('click', this.triggerStart);

    setTimeout(() => {
      document.body.style.overflow = '';
    }, 550);
  }
});
