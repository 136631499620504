import { CustomElement } from 'aurelia';

import Template from './gallery.html';

export default CustomElement.define({
  name: 'gallery',
  template: Template,
  bindables: [ 'rows' ]
}, class {
  binding = () => {
    window.addEventListener('scroll', this.onScroll);
    this.scrollInterval = setInterval(this.onScroll, 500);
  }

  unbinding = () => {
    window.removeEventListener('scroll', this.onScroll);
    clearInterval(this.scrollInterval);
  }

  onScroll = (e) => {
    this.rows.forEach((row) => {
      const boundingRect = row.element.getBoundingClientRect();
      row.offTop = boundingRect.bottom <= 100;
      row.offBottom = boundingRect.top > window.innerHeight - 100;
    });
  }
});
