import { CustomElement } from 'aurelia';

import Template from './blog.html';

import Gallery from './gallery';

export default CustomElement.define({
  name: 'blog-page',
  template: Template,
  dependencies: [ Gallery ]
}, class {
  constructor() {
    this.galleryRows = [
      {items: [
        {url: '/assets/0I1A7431.JPEG', title: 'The Courtney Room (Promos // January 2024)'}
      ]},
      {items: [
        {url: '/assets/DSC_2542-Enhanced-NR.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/DSC_2304-Enhanced-NR.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/DSC_2552-Enhanced-NR.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
      ]},
      {items: [
        {url: '/assets/0I1A7659.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/0I1A7610.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/0I1A7816.JPEG', title: 'The Courtney Room (Promos // January 2024)'}
      ]}
    ];
  }
});
