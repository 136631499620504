import { CustomElement } from 'aurelia';

import Gallery from './gallery';

import Template from './portfolio.html';

export default CustomElement.define({
  name: 'portfolio-page',
  template: Template,
  dependencies: [ Gallery ]
}, class {
  constructor() {
    this.galleryRows = [
      {items: [
        {url: '/assets/DSC_2672-Edit.jpg', title: 'Ghost Planet (Promos // June 2023)'}
      ]},
      {items: [
        {url: '/assets/0I1A3084.JPEG', title: 'Ghost Planet (Promos // June 2023)'},
        {url: '/assets/DSC_2606-Edit.jpg', title: 'Ghost Planet (Promos // June 2023)'},
        {url: '/assets/0I1A2919.JPEG', title: 'Ghost Planet (Promos // June 2023)'}
      ]},
      {items: [
        {url: '/assets/DSC_0806-Edit.JPG', title: 'The Courtney Room (Promos // April 2023)'}
      ]},
      {items: [
        {url: '/assets/DSC_0685-Edit.jpeg', title: 'The Courtney Room (Promos // April 2023)'},
        {url: '/assets/0I1A8624.jpeg', title: 'The Courtney Room (Promos // April 2023)'}
      ]},
      {items: [
        {url: '/assets/0I1A1931-Enhanced-NR.jpeg', title: 'Boy Leadfoot (Wicket Hall // June 10, 2023)'}
      ]},
      {items: [
        {url: '/assets/DSC_1777-Edit.jpeg', title: 'Cold Fame (Wicket Hall // June 10, 2023)'},
        {url: '/assets/0I1A2246-Enhanced-NR.jpeg', title: 'Shale (Wicket Hall // June 10, 2023)'},
        {url: '/assets/DSC_2447-Edit-Edit.jpeg', title: 'iLLScarlet (Wicket Hall // June 10, 2023)'}
      ]},
      {items: [
        {url: '/assets/DSC_2019-Edit.jpeg', title: 'Shale (Wicket Hall // June 10, 2023)'}
      ]},
      {items: [
        {url: '/assets/0I1A2089-Enhanced-NR.jpeg', title: 'Boy Leadfoot (Wicket Hall // June 10, 2023)'},
        {url: '/assets/_DSC7195.jpeg', title: 'August Burns Red (Vogue // 2018)'},
        {url: '/assets/DSC_1582-Edit.jpeg', title: 'Cold Fame (The Duke Saloon // May 18, 2023)'}
      ]},
      {items: [
        {url: '/assets/0I1A1271-Enhanced-NR.jpeg', title: 'Cold Fame (Wicket Hall // June 10, 2023)'}
      ]},
      {items: [
        {url: '/assets/IMG_0072.WEBP', title: 'Air Combat // Promos)'},
        {url: '/assets/_E8A7484.JPEG', title: 'Mad Child'},
        {url: '/assets/_E8A5275.JPEG', title: 'Dear Rouge'}
      ]}
    ];
  }
});
