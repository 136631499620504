import { CustomElement } from 'aurelia';

import Gallery from './gallery';

import Template from './home.html';

export default CustomElement.define({
  name: 'home-page',
  template: Template,
  dependencies: [ Gallery ]
}, class {
  constructor() {
    this.galleryRows = [
      {items: [
        {url: '/assets/0I1A7340.JPEG', title: 'The Courtney Room (Promos // January 2024)'}
      ]},
      {items: [
        {url: '/assets/DSC_2546-Enhanced-NR.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/DSC_2418-Enhanced-NR.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/DSC_2555-Enhanced-NR.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
      ]},
      {items: [
        {url: '/assets/0I1A7382-Enhanced-NR.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/0I1A7460.JPEG', title: 'The Courtney Room (Promos // January 2024)'},
        {url: '/assets/0I1A7479.JPEG', title: 'The Courtney Room (Promos // January 2024)'}
      ]}
    ];
  }
});
